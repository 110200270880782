import { useGetPostQuery } from "../../../../../graphql"
import React, { FC, useEffect, useState } from "react"
import RightSide from "./components/RightSide"
import LeftSide from "./components/LeftSide"
import { DateTime } from "luxon"
import { Spin } from "antd"
import "./index.less"

const ManageBlock: FC<ManagePostBlockInterface> = ({ selectedPostId }) => {
  const { data: postData, loading } = useGetPostQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        id: { eq: String(selectedPostId) },
      },
    },
  })
  const post = postData?.appNewsPosts?.data?.[0]

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | object | FormData | null }>()
  const [filters, setFilters] = useState<{ [key: string]: string | object | FormData | null }>()

  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    regions: null,
    distributors: null,
  })

  const convertDateToObject = (date: Date | null | undefined, timeZone: string | null | undefined) => {
    const jsDate = new Date(date as any)
    const formattedTimeZone = timeZone?.replace("minus", "-")?.replace("plus", "+")
    const dateTime = DateTime.fromJSDate(jsDate, { zone: formattedTimeZone })
    const year = dateTime.year
    const month = String(dateTime.month).padStart(2, "0")
    const day = String(dateTime.day).padStart(2, "0")
    const hours = String(dateTime.hour).padStart(2, "0")
    const minutes = String(dateTime.minute).padStart(2, "0");
    const datePart = `${year}-${month}-${day}`
    const timePart = `${hours}:${minutes}`
    return {
      dateAndTime: {
        date: date ? datePart : "",
        time: date ? timePart : "",
        timeZone: date ? formattedTimeZone : "",
      },
    }
  }

  useEffect(() => {
    if ((selectedPostId && post) || !selectedPostId) {
      const filters = {
        image: null,
        currentImage: post?.attributes?.image?.data?.attributes?.url || "",
        title: post?.attributes?.title || "",
        description: post?.attributes?.body || "",
        youtubeLink: post?.attributes?.link || "",
        category: post?.attributes?.tag || "",
        region: post?.attributes?.regions?.data?.length! > 1
          ? "All"
          : options?.regions?.find(option => option.key == post?.attributes?.regions?.data?.[0]?.id)?.label
          || "",
        distributor:
          options?.distributors?.find(option => option.key == post?.attributes?.distributors?.data?.[0]?.id)?.label ||
          "",
        ...convertDateToObject(post?.attributes?.start_at, post?.attributes?.timeZone),
      }
      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [post, options])

  const isChangesDisabled = !!(initialFilters?.title && !selectedPostId)

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {!loading && filters && initialFilters ? (
        <>
          <LeftSide
            {...{
              post,
              options,
              filters,
              setOptions,
              setFilters,
              initialFilters,
              selectedPostId,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              post,
              filters,
              setFilters,
              isChangesDisabled,
            }}
          />
        </>
      ) : (
        <Spin className={"posts-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
