import { Modal } from "antd";
import { FC } from "react";
import './index.less'
import { CloseOutlined } from "@ant-design/icons"
const TranslationModal: FC<TranslationModalProps> = ({ setOpen, open, onOk, onCancel }) => {
    const onOkGeneric = () => {
        onOk()
        setOpen(false)
    }
    const onCancelGeneric = () => {
        onCancel()
        setOpen(false)
    }

    return <Modal onOk={onOkGeneric} className="language-modal"
        width={432}
        closable={false}
        onCancel={onCancelGeneric}
        open={open}
        cancelText={'Proceed Without Saving'}
        okText={'Save Now'}
        cancelButtonProps={{
            type: 'primary',
            className: 'confiramtion-btn'
        }}
        okButtonProps={{
            type: 'default',
            className: 'confiramtion-btn'
        }}  >
        <div className="header">
            <p className="title">Unsaved Changes</p>
            <CloseOutlined color="#1C1C23" width={24} height={24} className="close-icon" onClick={onCancelGeneric} />
        </div>


        <p className="text">Are you sure? You haven&apos;t saved your changes. If you don&apos;t save, the text will be auto-translated. Please click &apos;Save Text&apos; to keep your edits before creating the product.</p>
    </Modal>
}

export default TranslationModal;