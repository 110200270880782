import { Form as AntForm, Checkbox, Divider, Form, Input, Modal, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useDeleteMoreSectionTypeMutation, useGetRegionsLazyQuery } from "../../../../../../../graphql"
import { LargeTrash } from "../../../../../../../icons"
import "./index.less"
import { CheckboxValueType } from "antd/es/checkbox/Group"

const ExtraModal: FC<MoreSectionsExtraModalInterface> = ({
  id,
  mode,
  refetch,
  filters,
  setFilters,
  handleExtraModalCancel,
}) => {
  const [deleteSection] = useDeleteMoreSectionTypeMutation()
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ [key: string]: { value: string, label: string, id?: string }[] }>({
    regions: []
  })
  const [regions, setRegions] = useState<CheckboxValueType[]>([])
  const [getRegions] = useGetRegionsLazyQuery()

  const allOption = options.regions?.[0];
  const restRegionOptions = options.regions?.slice(1);

  const parentModalElement = document.querySelector(".ant-modal.more") as HTMLDivElement | null

  const itemCreator = (item: any) => ({
    value: item?.attributes?.name,
    label: item?.attributes?.name,
    id: item?.id
  })

  const onChange = (regionName: CheckboxValueType) => {
    const maxLength = options.regions.length
    const lengthToActivateAll = maxLength - 2
    const allRegionsNames = restRegionOptions.map(({ value }) => value)
    setError(null)
    if (regions.includes(regionName) && regionName !== 'all')
      setRegions(prevState => prevState.filter(r => ![regionName, 'all'].includes(r)))
    else
      setRegions(prevState => prevState.length === lengthToActivateAll ? [...prevState, regionName, 'all'] : [...prevState, regionName])
    if (regionName === 'all' && regions.length <= lengthToActivateAll)
      setRegions([...allRegionsNames, regionName])
    if (regionName === 'all' && regions.length === maxLength)
      setRegions([]);
  }



  const getItems = async () => {
    const regionsData = await getRegions()
    const regionsOptions = regionsData?.data?.regions?.data
      .map((item: any) => itemCreator(item))
      .filter((item: any) => item.value)
    setOptions({
      regions: [{ value: 'all', label: "All" }, ...(regionsOptions || [])],
    });
  }

  const onCancel = () => {
    setError(null)
    setRegions([])
    handleExtraModalCancel()
    parentModalElement?.style.setProperty("opacity", null)
  }

  const onOk = async () => {
    setLoading(true)
    let newFilters = JSON.parse(JSON.stringify(filters))
    const regionsToSend = regions.map(region => {
      const regionOption = options?.regions?.find((option) => option.value === region);
      return regionOption;
    }).filter(e => e?.value !== 'all')

    if (mode !== 'delete' && !name) {
      setError('Please create name for your section');
      setLoading(false);
      return
    }

    if (mode !== 'delete' && regionsToSend.length === 0) {
      setError('Please select at least one region');
      setLoading(false);
      return
    }

    switch (mode) {
      case "edit":
        {
          const itemIndex = filters.findIndex(item => item?.id == id)
          newFilters[itemIndex].label = name
          newFilters[itemIndex].regions = [...regionsToSend]
        }
        setLoading(false)
        setFilters(newFilters)
        onCancel()
        break

      case "create":
        {
          newFilters.push({
            id: uuidv4(),
            itemId: null,
            label: name,
            name: name?.toLowerCase().replace(" ", "_"),
            isHidden: false,
            regions: [...regionsToSend]
          })
        }
        setLoading(false)
        setFilters(newFilters)
        onCancel()
        break

      case "delete": {
        try {
          await deleteSection({
            variables: {
              input: {
                id: newFilters.find((item: any) => item?.id == id).itemId,
              },
            },
          })
          await refetch()
          newFilters = newFilters.filter((item: any) => item?.id !== id)
          setLoading(false)
          setFilters(newFilters)
          onCancel()
        } catch (error) {
          setLoading(false)
        }
      }
    }
    setRegions([])
  }

  useEffect(() => {
    getItems()
    if (mode) parentModalElement?.style.setProperty("opacity", "0")
    if (id) {
      setName(filters.find(item => item.id == id)!.label)
      const chosenRegions = filters.find(item => item.id == id)?.regions
      const existingRegionsNames = chosenRegions!.map(reg => reg.value)
      setRegions(existingRegionsNames.length === 3 ? [...existingRegionsNames, 'all'] : existingRegionsNames)
    }

    else {
      setName("")
    }
  }, [mode])

  return (
    <Modal open={!!mode} onCancel={onCancel} className={"more-extra"} footer={null}>
      {mode == "delete" && <LargeTrash className={"trash"} />}
      <div>
        <div className={"title"}>
          {mode == "edit" && "Rename section"}
          {mode == "delete" && "Delete Section"}
          {mode == "create" && "Create section"}
        </div>
        <div className={"additional-description"}>
          {mode == "edit" && "Are you sure you want to edit this section?"}
          {mode == "create" && "Please specify the name of the new section."}
          {mode == "delete" &&
            "Are you sure you want to delete this section? Deleting it will also remove all associated information."}
        </div>
      </div>

      {(mode == "edit" || mode == "create") && (
        <Form layout={"vertical"}>
          <AntForm.Item label={"Name"} name='name'>
            <div className={"input-wrapper"}>
              <Input
                placeholder={"Type here"}
                onChange={e => setName(e.target.value.substring(0, 22))}
                value={name}
              />
            </div>
          </AntForm.Item>
          {options.regions &&
            <AntForm.Item className={'checkbox-group-wrapper label'} label={"Region:"} name={'regions'} >
              <div>
                <Checkbox.Group
                  value={regions}
                >
                  <Checkbox value={allOption.value} checked={true} onChange={(e) => onChange(e.target.value)}>{allOption.label}</Checkbox>
                  <Divider />
                  {restRegionOptions.map((option) => (
                    <Checkbox key={option.value} value={option.value} checked={regions.includes(option.value)} onChange={(e) => onChange(e.target.value)}>
                      {option.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
                {error && <div className="error-message" color="red">{error}</div>} {/* Error message */}
              </div>
            </AntForm.Item>
          }
        </Form>
      )
      }

      <div className={"buttons-wrapper"}>
        <div onClick={onCancel}>Cancel</div>
        <div onClick={onOk}>{mode == "delete" ? "Delete" : "Save"}</div>
        {loading && <Spin />}
      </div>
    </Modal >
  )
}

export default ExtraModal