import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import {
  useCreateOrUpdateUserMutation,
  useGetEnumsLazyQuery,
  useGetRegionsLazyQuery,
} from "../../../../../../../../../graphql"
import { BlockIcon, SaveIcon, SearchIcon, UnblockIcon } from "../../../../../../../../../icons"
import { areAllKeysPopulated, areObjectsEqual, capitalizeEveryWord, checkFormNotDone, truncate } from "../../../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../../../components/auth"

const Form: FC<RoleManageBlockLeftSideFormInterface> = ({
  user,
  blocked,
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  selectedUserId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { permissions, region } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Roles")?.blocks?.find((block: any) => block.name == "Guest Roles")

  const [createOrUpdateUser] = useCreateOrUpdateUserMutation()

  const [getRegions] = useGetRegionsLazyQuery()
  const [getTypes] = useGetEnumsLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async () => {
    setLoading(true)

    const itemCreator = (item: any, type: string) => {
      return {
        value: type == "region" ? item?.attributes?.name : item,
        label: type == "region" ? item?.attributes?.name : capitalizeEveryWord(item),
        key: item?.id,
      }
    }

    const regionsData = await getRegions()
    let regionsOptions = regionsData?.data?.regions?.data.map((item: any) => itemCreator(item, "region"))

    if (region.name && regionsOptions) {
      regionsOptions = regionsOptions.filter(regionOption => region.name == regionOption.label)
    }
    const typesData = await getTypes({
      variables: { input: { collection: "api::guest.guest", fieldName: "guest_type" } },
    })
    const typesOptions = typesData?.data?.getEnum
      ?.enum!.map((item: any) => itemCreator(item, "type"))
      .map(item => ({ ...item, label: item.label }))

    setOptions({
      types: typesOptions,
      regions: regionsOptions,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    let { name, email, type, region } = filters as any

    try {
      setFinishLoading(true)
      const response = await createOrUpdateUser({
        variables: {
          input: {
            ...(selectedUserId ? { user_id: selectedUserId } : {}),
            guest_type: type,
            data: {
              name,
              role: "Guest",
              email: email.trim().toLowerCase(),
              isAppActivated: user?.attributes?.isAppActivated || false,
              region: options.regions!.find(option => option.label == region)!.key!,
            },
          },
        },
      })

      if (response?.data?.createOrUpdateUser?.success) setInitialFilters(filters)
      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (type: string, name: string, options: SelectItems, disabled: boolean) => (
    <AntForm.Item name={name} label={name} required={true}>
      <div className={"input-wrapper"}>
        <Select
          showSearch
          options={options}
          disabled={disabled}
          optionFilterProp='children'
          defaultActiveFirstOption={true}
          filterOption={filteredOption as any}
          onChange={data => handleValuesChange(data, type)}
          value={options && filters?.[type] ? truncate(filters?.[type], 40) : ""}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon style={{ opacity: filters?.[type] ? 0 : 1 }} />}
        />
      </div>
    </AntForm.Item>
  )

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} required={true}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          value={(filters?.[type] as string) || ""}
          disabled={type === "email" && !!initialFilters?.email}
          onChange={e => handleValuesChange(e.target.value, type)}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    const isDifferentRootValues = !areObjectsEqual(filters!, initialFilters!)
    const isRootValuesValid = areAllKeysPopulated(filters!, [])
    checkFormNotDone({ isDifferentRootValues })
    setIsFormReady(isDifferentRootValues && isRootValuesValid)
    if (errorMessage) setErrorMessage("")
    if (!options?.regions && !options?.types) getItems()
  }, [filters, initialFilters])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper guest-roles"}>
          <div className={"block"}>
            <div className={"title"}>
              <div>USER</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createInputElement("name", "Name")}
              {createInputElement("email", "Email")}
              {createSelectElement("type", "Role", options.types!, false)}
              {createSelectElement("region", "Region", options.regions!, false)}
            </div>
          </div>

          <div className={"error"} style={{ opacity: errorMessage ? 1 : 0 }} children={errorMessage} />
        </div>
      </AntForm>

      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition key={JSON.stringify(blocked)} timeout={250} classNames={"fade"} appear={true} unmountOnExit>
          <>
            {selectedUserId && blockPermissions?.delete ? (
              <button onClick={showModal} className={`${!blocked ? "block-role" : "unblock-role"}`}>
                {!blocked ? "Block User" : "Unblock User"}
                {!blocked ? <BlockIcon /> : <UnblockIcon />}
              </button>
            ) : null}
          </>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default Form
