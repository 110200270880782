import React, { FC, useEffect, useMemo, useState } from "react"
import { useAuth } from "../../../../components/auth"
import { useSendPasswordMutation } from "../../../../graphql"
import { Form as AntForm, Button, ConfigProvider, Input, Spin } from "antd"
import { ReactComponent as ArrowForward } from "../../../../icons/white-arrow-forward.svg"
import "./index.less"
import { SpecialRecoverMessage } from "./variables"

const Form: FC<LoginFormProps> = ({ forgotPasMode, changeMode, forgotPasModeOnly }) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const [sendPassword] = useSendPasswordMutation()
  const [isNewPasswordSent, setIsNewPasswordSent] = useState<boolean>(false)

  const [email, setEmail] = useState<string>(new URLSearchParams(window.location.search).get('email') || '')
  const [password, setPassword] = useState<string>()

  const [loading, setLoading] = useState(false)

  const { login, loginError, setLoginError } = useAuth()

  const sendPasswordText = useMemo(() => {
    return forgotPasModeOnly ? "Please go back to KEVIN.MURPHY Insider and login in with temproary password we sent you on email."
      : "We sent you email with the temporary password"
  }, [])

  const renderLoginError = (loginError: string) => {
    return loginError.includes(".com") ? <SpecialRecoverMessage /> : loginError
  }
  const validateEmail = (email: string) => {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/
    return emailRegExp.test(email)
  }
  const validatePassword = (password: string) => {
    const disallowedSymbolsAndSpaces = /[!@#$%^&*()_+={}[\]\\|:;"'<>,.?/~\s]/
    return password?.length >= 8 && !disallowedSymbolsAndSpaces.test(password)
  }

  const handleValuesChange = (_: any, allValues: LoginInput) => {
    setLoginError("")
    setEmail(allValues?.email)
    setPassword(allValues?.password)
    setIsFormValid(
      forgotPasMode
        ? validateEmail(allValues?.email)
        : validateEmail(allValues?.email) && validatePassword(allValues?.password!)
    )
  }

  const handleChangeMode = () => {
    setLoginError("")
    changeMode(!forgotPasMode)
  }

  const handleLogin = async () => {
    if (email && password) {
      setLoading(true)
      await login({ email, password })
      setLoading(false)
    }
  }

  const handleForgotPas = async (allValues: LoginInput) => {
    setLoading(true)

    try {
      const response = await sendPassword({
        variables: {
          input: {
            isAdminForgot: true,
            isRecover: true,
            email: allValues.email,
          }
        }
      })
      if (response?.data?.sendPassword?.success) {
        setIsNewPasswordSent(true)
      } else {
        setLoginError("Try another email")
      }

      setLoading(false)
    } catch (error: any) {
      setLoginError(error?.message)
      setLoading(false)
    }
  }


  const handleFinish = (allValues: LoginInput) => {
    if (!forgotPasMode && isFormValid) {
      handleLogin()
    } else {
      handleForgotPas(allValues)
    }
  }

  useEffect(() => {
    if (forgotPasModeOnly) {
      setIsFormValid(validateEmail(email))
    }
  }, [])

  return (
    <div className={"form-wrapper"}>
      <div className={"title"}>{forgotPasMode ? "Forgot Password?" : "Admin Portal"}</div>
      <div className={"subtitle"}>
        {forgotPasMode ? "Please enter your email to reset the password." : "Log in to KM Admin Portal."}
      </div>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Button: {
              colorPrimary: "#BEC2CA",
              colorPrimaryHover: "#000000",
              colorText: "#ffffff",
              borderRadius: 0,
            },
          },
        }}
      >
        <AntForm layout='vertical' onFinish={handleFinish} requiredMark={false} onValuesChange={handleValuesChange}>
          <AntForm.Item
            label='Email'
            name='email'
            initialValue={email}
            help={(email && !validateEmail(email) && "Email is incorrect") || (forgotPasMode && renderLoginError(loginError))}
            className={email && !validateEmail(email) ? "invalid" : ""}
          >
            <div className={`input-wrapper ${email && !validateEmail(email) && "invalid"}`}>
              <Input value={email} placeholder={"Enter your email"} autoCorrect="off" autoCapitalize="off" />
            </div>
          </AntForm.Item>
          <AntForm.Item
            label='Password'
            name='password'
            help={(password && !validatePassword(password) && "Password is incorrect") || loginError}
            className={password && !validatePassword(password) ? "invalid" : forgotPasMode ? "transparent" : ""}
          >
            <div className={`input-wrapper ${password && !validatePassword(password) && "invalid"}`}>
              <Input.Password placeholder={"Enter your password"} disabled={forgotPasMode} />
            </div>
          </AntForm.Item>
          {
            isNewPasswordSent && forgotPasMode &&
            <span className={"info"}>{sendPasswordText}</span>
          }
          <Button type='primary' htmlType='submit' disabled={!isFormValid || (isNewPasswordSent && forgotPasMode)}>
            {forgotPasMode ? "Continue" : "Log in"}
            {loading ? <Spin /> : <ArrowForward />}
          </Button>
        </AntForm>
        {!forgotPasModeOnly && (
          <div className={"forgot-password"} onClick={handleChangeMode}>
            {forgotPasMode ? (
              <>
                Return to <span>Log In</span> page.
              </>
            ) : (
              <>Forgot password?</>
            )}
          </div>)}
      </ConfigProvider>
    </div>
  )
}

export default Form