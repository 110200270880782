import { FC, useMemo, useState } from "react"
import Switcher from "../Switcher"
import "./index.less"


const TransatableEditableContentWrapper: FC<EditableTransaltableContentProps> = ({
    isAutoTranslateEnabled,
    toggleSwitcher,
    selectedLanguage,
    children,
    renderApplyButton,
    translate,
    text,
    isTranslationLoading
}) => {
    return (
        <div>
            {children}
            <div className='translation-handler-wrapper'>
                <Switcher
                    isChecked={isAutoTranslateEnabled}
                    isDisabled={false}
                    onChange={() => toggleSwitcher()}
                    title='Auto-translate'
                />
                {renderApplyButton && selectedLanguage.value === "en" && (
                    <button
                        type={"button"}
                        onClick={() => translate({ text })}
                        className={`apply ${!isAutoTranslateEnabled && 'hidden'} ${text && !isTranslationLoading && "ready"}`}
                        disabled={!text && !isTranslationLoading}
                    >Apply
                    </button>
                )}
            </div>
        </div >
    )
}

export default TransatableEditableContentWrapper
