import React, { createContext, FC, useContext, useEffect, useState } from "react"
import { useMeQuery } from "../../graphql"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") ?? ""

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined)

export function useAuth(): AuthContextInterface {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [loginError, setLoginError] = useState<string>("")
  const [permissions, setPermissions] = useState<PermissionsInterface>()
  const { data: me, error, loading } = useMeQuery()

  const logout = () => {
    localStorage.clear()
    window.location.href = "/login"
  }

  const login = async ({ email, password }: { email: string; password: string }) => {
    await fetch(url + "/api/auth/local", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password: password,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (
          response?.accessToken &&
          response?.refreshToken &&
          response?.user?.role?.name?.toLowerCase()?.includes("adminportal")
        ) {
          localStorage.setItem("accessToken", response?.accessToken as string)
          localStorage.setItem("refreshToken", response?.refreshToken as string)
          localStorage.setItem('bugsnagUserInfo', JSON.stringify({
            id: response.user.id,
            username: response.user.username,
            email: response.user.email
          }) as string)
          window.location.href = "/account/dashboard/analytics"
        } else {
          setLoginError("Try another email or password")
        }
      })
  }

  const generatePermissions = () => {
    const role = me?.me?.role?.data?.attributes?.name?.toLowerCase()?.replace("adminportal ", "")
    const createBlocks = (blocks: string[]) => blocks.map(block => ({ name: block, edit: true, delete: true }))

    let permissions: PermissionsInterface = {
      firstMenu: [
        {
          name: "Dashboard",
          blocks: createBlocks(["Analytics", "Leaderboard", "Competition"]),
        },
        {
          name: "Team",
          blocks: createBlocks(["DSC", "Sales Manager", "Distributor", "Region", "Salon"]),
        },
        {
          name: "Content",
          blocks: createBlocks(["Posts", "Events", "Products", "Assets", "Notifications", "More", "Links"]),
        },
        {
          name: "Roles",
          blocks: createBlocks(["Admin Roles", "Guest Roles"])
        },
      ],
      secondMenu: [{ name: "Activity Log" }, { name: "Profile" }, { name: "Log Out" }],
    }

    const removeItem = (list: string[]) => {
      permissions = {
        firstMenu: permissions.firstMenu.filter((item: any) => !list.includes(item.name)),
        secondMenu: permissions.secondMenu.filter((item: any) => !list.includes(item.name)),
      }
      permissions = {
        ...permissions,
        firstMenu: permissions.firstMenu.map(item =>
          !item?.blocks ? item : { ...item, blocks: item?.blocks.filter((item: any) => !list.includes(item.name)) }
        ),
      }
    }

    const disableEdit = (list: string[]) => {
      permissions = {
        ...permissions,
        firstMenu: permissions.firstMenu.map(item =>
          !item?.blocks
            ? item
            : {
              ...item,
              blocks: item?.blocks.map((item: any) => (!list.includes(item.name) ? item : { ...item, edit: false })),
            }
        ),
      }
    }

    const disableDelete = (list: string[]) => {
      permissions = {
        ...permissions,
        firstMenu: permissions.firstMenu.map(item =>
          !item?.blocks
            ? item
            : {
              ...item,
              blocks: item?.blocks.map((item: any) =>
                !list.includes(item.name) ? item : { ...item, delete: false }
              ),
            }
        ),
      }
    }

    switch (role) {
      case "super admin":
        break
      case "editor":
        removeItem(["Team", "Roles", "Products", "More", "Links"])
        break
      case "admin":
        removeItem(["Admin Roles", "Products", "Assets", "More", "Links"])
        disableDelete(["Events", "Notifications"])
        break
      case "viewer":
        removeItem(["Team", "Roles", "Products", "Assets", "Notifications", "More", "Activity Log", "Links"])
        disableEdit(["Posts", "Events"])
        disableDelete(["Posts", "Events"])
        break
    }

    return permissions
  }

  useEffect(() => {
    if (me?.me?.role?.data?.attributes?.name) {
      const permissions = generatePermissions()
      setPermissions(permissions)
    }
  }, [loading])

  useEffect(() => {
    let isUnauthorized
    if (error?.networkError && error.networkError instanceof Object && "response" in error.networkError) {
      isUnauthorized = error?.networkError?.response?.status == 401
    }
    if (isUnauthorized && !loading) {
      logout()
    }
  }, [error, loading])

  return (
    <AuthContext.Provider
      value={{
        avatar: me?.me?.avatar?.data?.attributes?.url ?? undefined,
        role: permissions ? me?.me?.role?.data?.attributes?.name ?? undefined : undefined,
        region: { name: me?.me?.region?.data?.attributes?.name, id: me?.me?.region?.data?.id },
        userId: me?.me?.id,
        permissions,
        logout,
        login,
        loginError,
        setLoginError,
        loading: loading && !!permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
