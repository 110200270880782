import { Button, Modal } from 'antd';
const showConfirm = ({ onOk }: { onOk: () => void }) => {
    const confirmModal = Modal.confirm({
        width: "432px",
        title: 'Unsaved Changes',
        content: 'You have unsaved changes in the current language. If you switch languages now, your edits will be lost. Do you want to continue without saving?',
        className: 'language-modal',
        footer: (
            <div className={"ant-modal-footer ok"}>
                <Button
                    className={"ant-btn-ok"}
                    onClick={() => {
                        confirmModal.destroy();
                    }}
                >
                    Cancel
                </Button >
                <Button
                    className={"ant-btn-cancel"}
                    type={"primary"}
                    onClick={() => {
                        onOk()
                        confirmModal.destroy();
                    }}
                >
                    Proceed Without Saving
                </Button>
            </div >
        ),
        cancelButtonProps: {
            type: 'primary',
            className: 'confirmation-btn',
        },
        okButtonProps: {
            type: 'default',
            className: 'confirmation-btn',
        },
    });
};

export default showConfirm