import { FC } from 'react'
import './index.less'

const Switcher: FC<SwticherProps> = ({ isDisabled, isChecked, onChange, title }) => {
    return <div className={"switch-holder"}>
        <label className={"switch"}>
            <input
                disabled={isDisabled}
                type={"checkbox"}
                checked={isChecked}
                onChange={onChange}
            />
            <span className={"slider round"} />
        </label>
        {title}
    </div>
}

export default Switcher