import React, { FC, useEffect, useState } from "react"
import RightSide from "./components/RightSide"
import LeftSide from "./components/LeftSide"
import { Spin } from "antd"
import "./index.less"

import {
  useGetProductBenefitsLazyQuery,
  useGetProductTypesLazyQuery,
  useGetProductQuery,
  useGetEnumsQuery,
} from "../../../../../graphql"

const pagination = { limit: 10000, start: 0 }

const ManageBlock: FC<ManageProductBlockInterface> = ({ selectedProductId }) => {
  const [getProductBenefit] = useGetProductBenefitsLazyQuery()
  const [getProductTypes] = useGetProductTypesLazyQuery()

  const { data: productData, loading: productsLoading } = useGetProductQuery({
    fetchPolicy: "network-only",
    variables: {
      id: String(selectedProductId),
    }
  })

  const { data: sizeEnums, loading: sizesLoading } = useGetEnumsQuery({
    variables: {
      input: {
        collection: "api::product.product",
        fieldName: "defaultSize",
      },
    },
  })

  const { data: colorEnums, loading: colorsLoading } = useGetEnumsQuery({
    variables: {
      input: {
        collection: "api::product.product",
        fieldName: "defaultColor",
      },
    },
  })

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: any }>()
  const [filters, setFilters] = useState<{ [key: string]: any }>()

  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    types: null,
    benefits: null,
    defaultSizes: null,
    defaultColors: null,
    brands: [
      {
        label: "KM",
        value: "KM",
        key: "0",
      },
      {
        label: "COLOR.ME / GLOSS",
        value: "other",
        key: "1",
      },
    ],
  })

  const product = productData?.product?.data

  const formatSize = (size: string) => {
    let updatedSize = size

    if (size?.includes("ml")) {
      updatedSize = size.replace("ml ", "") + " ml"
    }
    if (size?.includes("g")) {
      updatedSize = size.replace("g ", "") + " g"
    }
    if (size?.includes("VOL")) {
      updatedSize = size.replace("VOL. ", "") + " VOL."
    }

    return updatedSize!
  }

  const getItems = async (brand: string) => {
    const itemCreator = (item: any, index?: number, type?: string) => ({
      value: item?.attributes?.name || item,
      label:
        type == "size" ? formatSize(item) :
          type == "color" ? item?.replace("color-", "") :
            (item?.attributes?.name || item),
      key: item?.id || index,
    })

    const generateQueryVariables = (type: string) => {
      let result = {
        variables: {
          pagination,
          sort: "name:asc",
          filters: {},
        },
      }
      switch (type) {
        case "productTypes":
          result.variables.filters = {
            ...(brand == "KM"
              ? {
                or: [
                  {
                    brand: { eq: "KM" },
                  },
                  {
                    brand: { eq: "ALL" },
                  },
                ],
              }
              : {
                brand: { ne: "KM" },
              }),
          }
          break
        case "productBenefits":
          break
      }
      return result
    }

    const typesData = await getProductTypes({
      ...generateQueryVariables("productTypes"),
    })
    const typeOptions = typesData?.data?.productTypes?.data.map(item => itemCreator(item)).filter(item => item.value)

    const benefitData = await getProductBenefit({
      ...generateQueryVariables("productBenefits"),
    })
    const benefitOptions = benefitData?.data?.productBenefits?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)

    const defaultSizeOptions = sizeEnums?.getEnum?.enum?.map((item: any, i: number) => itemCreator(item, i, "size"))
    const defaultColorOptions = colorEnums?.getEnum?.enum?.map((item: any, i: number) => itemCreator(item, i, "color"))

    setOptions({
      ...options,
      defaultSizes: defaultSizeOptions!,
      defaultColors: defaultColorOptions!,
      types: typeOptions!,
      benefits: benefitOptions!,
    })
  }

  const findEnum = (type: string, value: string | null | undefined) => {
    let enumValue
    const normalizeEnumValue = (value: string | null | undefined): any => {
      return !value ? "" : value.replace(/[\W_]+/g, "_").replace(/_$/, "");
    }

    switch (type) {
      case "size":
        enumValue = options?.defaultSizes?.find(item => normalizeEnumValue(item?.value) == normalizeEnumValue(value))
          ?.value
        break
      case "color":
        enumValue = options?.defaultColors?.find(item => normalizeEnumValue(item?.value) == normalizeEnumValue(value))
          ?.value
    }
    return enumValue || ""
  }

  useEffect(() => {
    const brand = product?.attributes?.brand
    brand && sizeEnums && colorEnums && getItems(brand)
  }, [product, sizeEnums, colorEnums])

  useEffect(() => {
    filters?.brand && sizeEnums && colorEnums && getItems(filters.brand)
  }, [filters?.brand, sizeEnums, colorEnums])

  useEffect(() => {
    if ((selectedProductId && product && options.types && options.benefits) || !selectedProductId) {
      const filters = {
        media: null,
        name: product?.attributes?.title || "",
        title: product?.attributes?.descriptionTitle || "",
        description: product?.attributes?.description || "",
        defaultSize: findEnum("size", product?.attributes?.defaultSize),
        defaultColor: findEnum("color", product?.attributes?.defaultColor),
        type: product?.attributes?.type?.data?.attributes?.name || "",
        benefit: product?.attributes?.benefit?.data?.attributes?.name || "",
        currentImage: product?.attributes?.media?.data?.[0]?.attributes?.url || "",
        brand:
          selectedProductId && product?.attributes?.brand !== "KM"
            ? "COLOR.ME / GLOSS"
            : product?.attributes?.brand || "",
        items:
          product?.attributes?.items?.map(item => ({
            media: null,
            id: item!.id,
            SKU: item?.SKU,
            name: item?.name || "",
            size: findEnum("size", item?.size),
            color: findEnum("color", item?.color),
            currentImage: item!.media?.data?.attributes?.url || "",
          })) || [],
      }
      if (!initialFilters || !filters) {
        setInitialFilters(filters)
        setFilters(filters)
      }
    }
  }, [product, options.types, options.benefits])

  const isChangesDisabled = !!(initialFilters?.title && !selectedProductId)

  return (
    <div className={`block-manage-wrapper ${productsLoading && sizesLoading && colorsLoading && "center"}`}>
      {!productsLoading && !sizesLoading && !colorsLoading && filters && initialFilters ? (
        <>
          <LeftSide
            {...{
              product,
              options,
              filters,
              sizeEnums,
              colorEnums,
              setOptions,
              setFilters,
              formatSize,
              initialFilters,
              selectedProductId,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              product,
              filters,
              setFilters,
              isChangesDisabled,
            }}
          />
        </>
      ) : (
        <Spin className={"product-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
