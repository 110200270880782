import result, { useCreateProductLocalizationMutation, useCreateProductMutation, useDeleteTranslationsMutation, useGetProductLazyQuery, useTranslateLazyQuery, useUpdateProductMutation } from "../../../../../../../../../graphql"
import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, deepEqualArrays, setNewTranslationText, truncate } from "../../../../../../../../../utils"
import { ReactComponent as SearchIcon } from "../../../../../../../../../icons/search.svg"
import { ReactComponent as CloseIcon } from "../../../../../../../../../icons/close.svg"
import { ReactComponent as TrashIcon } from "../../../../../../../../../icons/trash.svg"
import { ReactComponent as SaveIcon } from "../../../../../../../../../icons/check.svg"
import { TextEditor } from "../../../../../../../components/TextEditor"
import { DownOutlined } from "@ant-design/icons"
import { Form as AntForm, Dropdown, Empty, Input, Select, Space, Spin } from "antd"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import "./index.less"
import { useAuth } from "../../../../../../../../../components/auth"
import TranslationModal from "../../../../../../../components/TranslationModal"
import { createPortal } from "react-dom"
import TransatableEditableContentWrapper from "../../../../../../../components/TranslatableEditableWrapper"
import { Languages, generateItems } from "../../../../../../../../../utils/variables/variables"


const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Form: FC<ProductManageBlockLeftSideFormInterface> = ({
  product,
  options,
  filters,
  showModal,
  setFilters,
  initialFilters,
  selectedProductId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Products")

  const [createProduct] = useCreateProductMutation()
  const [updateProduct] = useUpdateProductMutation()
  const [translate] = useTranslateLazyQuery()
  const [deleteTranslations] = useDeleteTranslationsMutation()

  const [isAutoTranslateEnabled, setAutoTranslateEnabled] = useState<boolean>(true)
  const [confirmedTranslationDisable, setConfirmedTranslationDisable] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState<{ title: string, value: string }>({ title: Languages.EN_FULL_TITLE, value: Languages.EN })
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState<boolean>(false)
  const [translationsVariants, setTranslationVariants] = useState<any[]>([])
  const [intitalVariants, setIntitalVariants] = useState<any[]>([])
  const [isTranslationLoading, setIsTranslationLoading] = useState<boolean>(false)




  const formatTranslates = useCallback((translates: any[]) => {
    if (translates.length === 0) {
      return []
    }
    return translates.map(e => { return { id: e?.id, detectedSourceLang: e?.detectedSourceLang, text: e?.text, targetLanguage: e?.targetLanguage } })
  }, [])


  const handleTranslation = async ({ text }: { text: string }) => {
    setIsTranslationLoading(true)
    try {
      const { data: translation } = await translate({
        variables: {
          input: {
            langs: [Languages.FR, Languages.DE, Languages.ES],
            text,
            onlyTranslationMode: true,
          }
        }
      })

      if (translation && translationsVariants[0]?.id) {
        const updatedVariants = translationsVariants.map((v) => { return { ...v, text: setNewTranslationText({ newTranslations: translation.translate?.translations, lang: v.targetLanguage }) } })

        setTranslationVariants(formatTranslates(updatedVariants) || [])
        setIsTranslationLoading(false)
        return
      }
      setTranslationVariants(formatTranslates(translation?.translate?.translations || []) || [])
      setIsTranslationLoading(false)
    } catch (error: any) {
      setIsTranslationLoading(false)
      setErrorMessage(truncate(error.message, 100))
    }
  }

  const toggleSwitcher = () => {
    if (!confirmedTranslationDisable) {
      setConfirmedTranslationDisable(true)
      setIsTranslationModalOpen(true)
      setAutoTranslateEnabled(!isAutoTranslateEnabled)
    }
    setTranslationVariants([])
    setAutoTranslateEnabled(!isAutoTranslateEnabled)
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleValuesChange = (value: any, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
      ...(type == "brand" && filters.benefit ? { benefit: "" } : {}),
      ...(type == "brand" && filters.type ? { type: "" } : {}),
    })
  }

  const handleFinish = async () => {
    let { media, name, title, description, brand, type, benefit, items, defaultSize, defaultColor, currentImage } =
      filters
    const normalizeEnumValue = (value: string): any => {
      return value.replace(/[\W_]+/g, "_").replace(/_$/, "");
    }

    items = items.map(
      (item: any) =>
        (item.currentImage || item.media) && {
          ...(item.id ? { id: item.id } : {}),
          SKU: item?.SKU || null,
          name: item.name || null,
          size: normalizeEnumValue(item.size) || null,
          color: normalizeEnumValue(item.color) || null,
          media: item.media || product?.attributes?.items?.find((product: any) => product.id == item.id).media.data.id,
        }
    )
    try {
      setFinishLoading(true)
      let imageId

      if (media) {
        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: media,
        })
        const uploadData = await uploadResponse.json()
        if (!uploadData?.[0]?.id) {
          throw new Error("failed to upload image")
        }
        imageId = uploadData?.[0]?.id
      }

      const data: ProductInput = {
        items,
        title: name,
        isNew: false,
        isBestseller: false,
        description,
        descriptionTitle: title,
        brand: brand == "KM" ? brand : "COLOR_ME_GLOSS",
        defaultSize: normalizeEnumValue(defaultSize) || null,
        defaultColor: normalizeEnumValue(defaultColor) || null,
        type: options?.types?.find(option => option?.label == type)!.key,
        ...(imageId ? { media: imageId } : currentImage ? {} : { media: null }),
        benefit: options?.benefits?.find(option => option?.label == benefit)?.key || null,
      }

      let newProduct: any
      let updatedProduct: any
      let newTranslation: any[] | null | undefined = null

      if (!selectedProductId) {
        newProduct = await createProduct({
          variables: {
            data,
          },
        });
        if (translationsVariants.length !== 3 && isAutoTranslateEnabled) {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: newProduct.data.createProduct.data.id,
                onlyTranslationMode: false,
                collection: "api::product.product",
                localizedField: 'description'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        } else {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: newProduct.data.createProduct.data.id,
                onlyTranslationMode: false,
                collection: "api::product.product",
                variants: translationsVariants,
                localizedField: 'description'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        }
      } else {
        updatedProduct = await updateProduct({
          variables: {
            id: String(selectedProductId),
            data,
            locale: "en"
          },
        });
        if (!isAutoTranslateEnabled && translationsVariants.every(e => e.id)) {
          await deleteTranslations({
            variables: {
              input:
              {
                collection: "api::product.product",
                localizationsIds: translationsVariants.map(e => e.id),
                originalId: updatedProduct.data.updateProduct.data.id,
              }
            }
          })
        }
        else if (translationsVariants.length !== 3 && isAutoTranslateEnabled) {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: updatedProduct.data.updateProduct.data.id,
                onlyTranslationMode: false,
                collection: "api::product.product",
                localizedField: 'description'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        } else {
          if (translationsVariants.every(e => e.id)) {
            await Promise.all(
              translationsVariants.map((variant) => {
                if (variant.id) {
                  updateProduct({
                    variables: {
                      id: variant.id,
                      data: {
                        description: variant.text,
                      },
                      locale: variant.targetLanguage
                    },
                  });
                }
              })
            );
          } else {
            await translate({
              variables: {
                input: {
                  langs: [Languages.FR, Languages.DE, Languages.ES],
                  text: description,
                  originalId: updatedProduct.data.updateProduct.data.id,
                  onlyTranslationMode: false,
                  collection: "api::product.product",
                  localizedField: 'description'
                }
              }
            })
          }

        }
      }

      if (newProduct?.data?.createProduct?.data?.id || updatedProduct?.data?.updateProduct?.data?.id) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.currentImage =
          newProduct?.data?.createProduct?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          updatedProduct?.data?.updateProduct?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          ""
        newFilters.media = null

        const newItems = newProduct?.data?.createProduct?.data?.attributes?.items
        const updatedItems = updatedProduct?.data?.updateProduct?.data?.attributes?.items

        newFilters.items = newFilters.items.map((item: any, i: number) => {
          return {
            ...item,
            media: null,
            currentImage:
              newItems?.[i]?.media?.data?.attributes?.url ||
              updatedItems?.[i]?.media?.data?.attributes?.url ||
              ""
          }
        }
        )

        newTranslation ? setTranslationVariants(formatTranslates(newTranslation)) : setTranslationVariants(formatTranslates(translationsVariants))
        newTranslation ? setIntitalVariants(formatTranslates(newTranslation)) : setIntitalVariants(formatTranslates(translationsVariants))
        setSelectedDescriptionLanguage({ title: Languages.EN_FULL_TITLE, value: Languages.EN })
        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      console.log(error);
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    isRequired = true,
    isDisabled = false
  ) => {
    const value = filters?.[type] || ""
    const canBeRemoved = !isDisabled && !isRequired && value

    return (
      <AntForm.Item name={name} label={name} required={isRequired}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div className={"remove-icon"} children={<CloseIcon />} onClick={() => handleValuesChange("", type)} />
          )}
          <Select
            showSearch
            disabled={isDisabled}
            value={value}
            optionFilterProp='children'
            onChange={data => handleValuesChange(data, type)}
            filterOption={filteredOption as any}
            options={options}
            defaultActiveFirstOption={true}
            notFoundContent={
              loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SearchIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string, isRequired = true) => (
    <AntForm.Item label={label} required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={(filters?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>)

  useEffect(() => {
    const existingVariants = product?.attributes?.localizations?.data?.map(({ id, attributes }: { attributes: any, id: string }) => {
      return { targetLanguage: attributes.locale, text: attributes.description, detectedSourceLang: "en", id }
    })
    setTranslationVariants(existingVariants || [])
    setIntitalVariants(existingVariants || [])
  }, [])

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["items", "defaultSize", "defaultColor", "benefit", "media", "currentImage"]
      const items = filters.items
      const isDifferentRootValues = !areObjectsEqual(filters, initialFilters, "items")
      const isRootValuesValid = areAllKeysPopulated(filters, notRequiredKeys)
      const isTranslationsChanged = !deepEqualArrays(intitalVariants || [], translationsVariants || [])

      checkFormNotDone({ isDifferentRootValues, isTranslationsChanged })
      const isDifferentItems =
        initialFilters.items?.length !== items?.length ||
        !!items.find((item: any, i: number) => !areObjectsEqual(item, initialFilters.items?.[i]))
      const isItemsDownloaded = !items.find((item: any) => item.media && isNaN(item.media))
      const isItemsValid =
        items.length && !items.find((item: any) => !item.currentImage && !item.media) && isItemsDownloaded

      setIsFormReady((isDifferentRootValues || isDifferentItems || isTranslationsChanged) && isRootValuesValid && isItemsValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters, translationsVariants])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper product"}>
          <div className={"block"}>
            <div className={"title"}>NAME</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("name", "Name")}</div>
          </div>

          <div className={"block"}>
            <div className={"title"}>TITLE</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("title", "Title")}</div>
          </div>
          <div className={"block"}>
            <div className="block-header">
              <div className={"title"}>DESCRIPTION</div>
              <Dropdown className={isTranslationLoading || !isAutoTranslateEnabled ? "dropdown-disabled" : ''} disabled={isTranslationLoading || !isAutoTranslateEnabled} menu={{ items: generateItems({ setSelectedDescriptionLanguage }) }}>
                <Space>
                  {selectedDescriptionLanguage.title}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
            <TransatableEditableContentWrapper
              isAutoTranslateEnabled={isAutoTranslateEnabled}
              selectedLanguage={selectedDescriptionLanguage}
              toggleSwitcher={toggleSwitcher}
              renderApplyButton={true}
              translate={handleTranslation}
              text={filters.description}
              isTranslationLoading={isTranslationLoading}>
              <div className={"inputs-wrapper wide-input"}>
                <TextEditor
                  englishVersion={filters.description}
                  handleMarkdown={handleValuesChange}
                  title={"Description"}
                  type={"description"}
                  language={selectedDescriptionLanguage.value}
                  fetchedMarkdown={product?.attributes?.description as string | undefined}
                  setTranslationVariants={setTranslationVariants}
                  translationsVariants={translationsVariants}
                />
              </div>
            </TransatableEditableContentWrapper>
          </div>

          <div className={"block"}>
            <div className={"title"}>GENERAL</div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("brand", "Brand", !options.brands, options.brands!, true, false)}
              {createSelectElement("type", "Type", !options.types, options.types!, true, !filters.brand)}
              {createSelectElement(
                "benefit",
                "Regimen",
                !options.benefits,
                options.benefits!,
                false,
                filters.brand != "KM"
              )}
            </div>
          </div>
          <div className={"block"}>
            <div className={"title"}>DEFAULT OPTIONS</div>
            <div className={"inputs-wrapper"}>
              {createSelectElement(
                "defaultSize",
                "Default Size",
                !options.defaultSizes,
                options.defaultSizes!,
                false,
                !filters.brand
              )}
              {createSelectElement(
                "defaultColor",
                "Default Color",
                !options.defaultColors,
                options.defaultColors!,
                false,
                !filters.brand
              )}
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm >

      {blockPermissions?.edit && (
        <button
          type={"submit"}
          onClick={handleFinish}
          className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
          disabled={!isFormReady || isChangesDisabled}
        >
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )
      }

      {
        selectedProductId && blockPermissions?.delete ? (
          <button onClick={() => showModal()} className={"delete"}>
            Delete
            <TrashIcon />
          </button>
        ) : null
      }
      {
        createPortal(<TranslationModal
          open={isTranslationModalOpen}
          onOk={() => setAutoTranslateEnabled(false)}
          onCancel={() => setAutoTranslateEnabled(true)}
          setOpen={setIsTranslationModalOpen} />, document.body)
      }
    </>
  )
}

export default Form