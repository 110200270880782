import { MenuProps } from "antd";
import DropdownItem from "../../pages/Account/components/DropdownItem";

export enum Languages {
  EN = "en",
  ES = "es",
  FR = "fr",
  DE = "de",
  EN_FULL_TITLE = 'English',
  ES_FULL_TITLE = 'Spanish',
  FR_FULL_TITLE = 'French',
  DE_FULL_TITLE = 'German'
}

export const generateItems =
  ({
    setSelectedDescriptionLanguage,
  }: {
    setSelectedDescriptionLanguage: ({ title, value }: { title: string, value: string }) => void;
  }): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        key: Languages.EN,
        label: (
          <DropdownItem
            title={Languages.EN_FULL_TITLE}
            onClick={() => {
              setSelectedDescriptionLanguage({ title: Languages.EN_FULL_TITLE, value: Languages.EN });
            }}
          />
        ),
      },
      {
        key: Languages.FR,
        label: (
          <DropdownItem
            title={Languages.FR_FULL_TITLE}
            onClick={() => {
              setSelectedDescriptionLanguage({ title: Languages.FR_FULL_TITLE, value: Languages.FR });
            }}
          />
        ),
      },
      {
        key: Languages.DE,
        label: (
          <DropdownItem
            title={Languages.DE_FULL_TITLE}
            onClick={() => {
              setSelectedDescriptionLanguage({ title: Languages.DE_FULL_TITLE, value: Languages.DE });
            }}
          />
        ),
      },
      {
        key: Languages.ES,
        label: (
          <DropdownItem
            title={Languages.ES_FULL_TITLE}
            onClick={() => {
              setSelectedDescriptionLanguage({ title: Languages.ES_FULL_TITLE, value: Languages.ES });
            }}
          />
        ),
      },
    ];

    return items;
  };



