import React, { FC } from "react"
import defaultPhoto from "../../../../../../../images/default-photo.png"
import distributorPlaceholder from "../../../../../../../images/distributor-placeholder.png"
import { formatDate, truncate } from "../../../../../../../utils"
import "./index.less"

const RightSide: FC<TeamManageBlockRightSideInterface> = ({ role, user, blocked, filters }) => {
  const avatarUrl =
    role == "Distributor Entity" ? distributorPlaceholder : user?.attributes?.avatar?.data?.attributes?.url

  let lastActivity;
  let logCount;

  switch (role) {
    case "DSC": {
      lastActivity = user?.attributes?.dsc?.data?.attributes?.last_activity;
      logCount = user?.attributes?.dsc?.data?.attributes?.log_count || 0
    }
      break
    case "Sales Manager": {
      lastActivity = user?.attributes?.sales_manager?.data?.attributes?.last_activity;
      logCount = user?.attributes?.sales_manager?.data?.attributes?.log_count || 0
    }
      break
    case "Distributor": {
      const logs = user?.attributes?.distributor?.data?.attributes?.logs
      const userLogs = logs?.find((log: any) => { return log.user_id == user.id })
      lastActivity = userLogs?.last_activity;
      logCount = userLogs?.log_count || 0
    }
  }

  return (
    <div className={"general-info-wrapper"}>
      {!avatarUrl && <div className={"default-text"}>User did not add photo yet</div>}
      <div className={"title"}>INFORMATION</div>
      {avatarUrl ? (
        <img src={avatarUrl} alt={"avatar"} />
      ) : (
        <>
          <img src={defaultPhoto} alt='media' />
          <div className={"default-text"}>User did not add photo yet</div>
        </>
      )}
      <div className={"data"}>
        <div>
          Name<span>{truncate(filters?.name || "-", 33)}</span>
        </div>
        {role !== "Distributor Entity" && (
          <React.Fragment>
            <div>
              Email<span>{truncate(filters?.email || "-", 33)}</span>
            </div>
            <div>
              Role<span>{role}</span>
            </div>
            {role == "DSC" && (
              <div>
                Sales Manager<span>{truncate(filters?.sales_manager || "-", 33)}</span>
              </div>
            )}
            {(role == "Distributor" || role == "Sales Manager") && (
              <div>
                Distributor<span>{truncate(filters?.distributor || "-", 33)}</span>
              </div>
            )}
          </React.Fragment>
        )}
        {(role == "Region" || role == "Distributor Entity") && (
          <div>
            Region<span>{truncate(filters?.region || "-", 33)}</span>
          </div>
        )}
        {role !== "Distributor Entity" && (
          <React.Fragment>
            <div>
              Date added<span>{formatDate(user?.attributes?.createdAt! as any)}</span>
            </div>
            <div>
              Last updated<span>{formatDate(user?.attributes?.updatedAt! as any)}</span>
            </div>

            {role !== "Distributor Entity" && role !== "Region" && (
              <>
                <div>
                  Last entree date
                  <span>{
                    lastActivity ? formatDate(lastActivity) : "-"
                  }</span>
                </div>
                <div>
                  Total app entries
                  <span>{logCount}</span>
                </div>
              </>
            )}

            <div>
              Status<span>{blocked ? "Blocked" : filters?.status}</span>
            </div>
            <div>
              Phone<span>{filters?.phone || "-"}</span>
            </div>
            <div>
              App version:<span>{user?.attributes?.appVersion || "-"}</span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default RightSide
