import { Form as AntForm, Input, InputNumber, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useAuth } from "../../../../../../../../../components/auth"
import { useCreateResourceMutation, useUpdateResourceMutation } from "../../../../../../../../../graphql"
import { CopyIcon, SaveIcon, TrashIcon } from "../../../../../../../../../icons"
import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, truncate } from "../../../../../../../../../utils"
import "./index.less"
import ToolTip from "../../../../../../../components/ToolTip"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Form: FC<PdfManageBlockLeftSideFormInterface> = ({
  filters,
  showModal,
  setFilters,
  selectedPdfId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { userId, permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu
    ?.find(item => item.name == "Content")
    ?.blocks?.find((block: any) => block.name == "Links")

  const [createResource] = useCreateResourceMutation()
  const [updateResource] = useUpdateResourceMutation()

  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  const handleValuesChange = (value: any, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    const { category, description, pages, file } = filters as any

    try {
      setFinishLoading(true)

      let fileId
      if (typeof file == "object") {
        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: file,
        })
        const uploadData = await uploadResponse.json()

        if (!uploadData?.[0]?.id) throw new Error("failed to upload pdf")

        fileId = uploadData?.[0]?.id
      }

      const data: AppResourceInput = {
        pages,
        description,
        author: userId,
        category: category.trim(),
        ...(fileId ? { file: fileId } : {}),
      }

      let newPdf
      let updatedPdf

      if (!selectedPdfId) {
        newPdf = await createResource({
          variables: {
            data,
          },
        })

      } else {
        updatedPdf = await updateResource({
          variables: {
            id: String(selectedPdfId),
            data,
          },
        })
      }

      const pdfId = newPdf?.data?.createAppResource?.data?.id || updatedPdf?.data?.updateAppResource?.data?.id
      if (pdfId) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.file = null
        newFilters.link = `${window.location.origin}/resources/${pdfId}`
        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const handleCopyClick = () => {
    if (filters?.link) {
      navigator.clipboard.writeText(filters.link)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch(error => console.error('Error copying text: ', error));
    }
  };

  const createInputNumberElement = () => (
    <div className={"numeric-input-wrapper"}>
      <div className={"numeric-input-title"} children={"Pages"} />
      <InputNumber
        min={1}
        value={filters?.pages || null}
        placeholder={"The number of pages accessible for viewing"}
        onChange={data => handleValuesChange(data, "pages")}
      />
    </div>
  )

  const createInputElement = (type: string, label: string, isRequired = true) => (
    <AntForm.Item label={label} required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          disabled={type == "link"}
          placeholder={type == "link" ? "Link will appear here after file creation" : "Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={(filters?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["link", "size"]
      if (selectedPdfId) notRequiredKeys.push("file")

      const isDifferentValues = !areObjectsEqual(filters, initialFilters)
      const isValuesValid = areAllKeysPopulated(filters, notRequiredKeys)
      checkFormNotDone({ isDifferentRootValues: isDifferentValues })
      setIsFormReady(isDifferentValues && isValuesValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper pdf"}>
          <div className={"block"}>
            <div className={"title"}>
              <div>GENERAL</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createInputElement("category", "Category")}
              {createInputNumberElement()}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DESCRIPTION</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("description", "Description")}</div>
          </div>

          <div className={"block"}>
            <div className={"title"}>LINK</div>
            <div className={"inputs-wrapper wide-input link"}>
              {createInputElement("link", "Link", false)}
              {finishLoading && !filters?.link && <Spin className={"link-icon"} />}
              {
                filters?.link &&
                <ToolTip title="the link has been copied" disabled={!copied} size={"fit-content"}>
                  <CopyIcon className={"link-icon copy"} onClick={handleCopyClick} />
                </ToolTip>
              }
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
        <button
          type={"submit"}
          onClick={handleFinish}
          className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
          disabled={!isFormReady || isChangesDisabled}
        >
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )}

      {selectedPdfId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
    </>
  )
}

export default Form
