import React, { FC } from "react"
import { useLocation, useNavigate } from "react-router"
import { ReactComponent as Arrow } from "../../../../icons/arrow_backward.svg"
import "./index.less"
import showConfirm from "../ConfirmModal"


const Header: FC<HeaderInterface> = ({ pageName }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const indicator = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createStatus = () => {
    if (Number(indicator) > 0) {
      return "/Edit"
    }
    switch (indicator) {
      case "new-user":
        return "/New User"
      case "new-distributor":
        return "/New Distributor"
      case "new-post":
        return "/New Post"
      case "new-product":
        return "/New Product"
      case "new-event":
        return "/New Event"
      case "new-asset":
        return "/New Asset"
      case "new-notification":
        return "/New Notification"
      case "new-role":
        return "/New Role"
      case "new-guest":
        return "/New Guest"
      case "new-link":
        return "/New Link"
      case "new-salon":
        return "/New Salon"
      default:
        return ""
    }
  }

  const goBack = () => {
    return navigate(pathname.split('/').slice(0, -1).join('/'))
  }

  const handleGoBack = () => {
    const key = "formNotDoneYet"
    const formNotDoneYet = localStorage.getItem(key);
    const showModal = formNotDoneYet ? JSON.parse(formNotDoneYet) : null;
    showModal ? showConfirm({
      onOk: () => {
        localStorage.setItem(key, JSON.stringify(false))
        goBack()
      }
    }) :
      goBack()
  }

  return (
    <header>
      <div
        className={"page-name"}
        children={
          <>
            {createStatus() && <Arrow onClick={handleGoBack} />}
            <div className={createStatus() ? "faded" : ""}>{pageName}</div>
            <span>{createStatus()}</span>
          </>
        }
      />
    </header>
  )
}
export default Header
