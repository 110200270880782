import React, { FC } from "react"
import imagePlaceholder from "../../../../../../../images/distributor-placeholder.png"
import { formatDate } from "../../../../../../../utils"
import "./index.less"
import ImageLoader from "../../../../../components/ImageLoader"

const RightSide: FC<RoleManageBlockRightSideInterface> = ({ user, filters }) => {
  const avatar = user?.attributes?.avatar?.data?.attributes?.url
  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      {avatar ?
        <ImageLoader filters={{}} setFilters={() => { }} fetchedImageURL={avatar} type={""} isChangesDisabled={true} /> :
        <img src={imagePlaceholder} alt={"media"} />
      }

      <div className={"data"}>
        <div>
          Name<span>{filters?.name || "-"}</span>
        </div>

        <div>
          Email<span>{filters?.email || "-"}</span>
        </div>

        <div>
          Role<span>{user?.attributes?.role?.data?.attributes?.name || "-"}</span>
        </div>

        <div>
          Region<span>{filters?.region || "All"}</span>
        </div>

        <div>
          Status<span>{user?.attributes?.blocked ? "Blocked" : (user?.attributes?.isAppActivated ? "Active" : "Inactive")}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(user?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(user?.attributes?.updatedAt)}</span>
        </div>
        <div>
          App version:<span>{user?.attributes?.appVersion || "-"}</span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
