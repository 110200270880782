import { Checkbox, CheckboxProps, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { v4 as uuidv4 } from "uuid"
import { useReorderMoreSectionMutation } from "../../../../../graphql"
import { EditSixDots, PencilIcon, SaveIcon, TrashGreyIcon } from "../../../../../icons"
import { areObjectsEqual } from "../../../../../utils"
import ExtraModal from "./components/ExtraModal"
import "./index.less"

const SectionsManagement: FC<MoreSectionsManagementInterface> = ({ data, refetch, handleModalCancel }) => {
  const [changeOrder] = useReorderMoreSectionMutation()

  const [initialFilters, setInitialFilters] = useState<LinkInterface[]>([])
  const [filters, setFilters] = useState<LinkInterface[]>([])
  const [loading, setLoading] = useState<boolean>(!filters)
  const [extraModalConfig, setExtraModalConfig] = useState<{ mode: string; id?: string } | null>()

  const isItemsEqual = areObjectsEqual(initialFilters, filters)

  const onCheckboxChange: CheckboxProps["onChange"] = e => {
    const itemIndex = +e.target.id! as number
    let newFilters = JSON.parse(JSON.stringify(filters))
    newFilters[itemIndex].isHidden = !e.target.checked
    setFilters(newFilters)
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const newItems = Array.from(filters)
    const [reorderedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, reorderedItem)
    setFilters(newItems)
  }

  const onSave = async () => {
    setLoading(true)
    try {
      const newOrder = filters.map(item => {
        const regions = item.regions!
        return {
          id: item.itemId,
          name: item.name,
          label: item.label,
          isHidden: item.isHidden,
          regions: regions
        }
      })
      const response = await changeOrder({ variables: { input: { newOrder } } })

      if (!response.data?.reorderMoreSection?.success) {
        throw Error
      } else {
        await refetch()
        setLoading(false)
        setTimeout(() => {
          handleModalCancel()
        }, 250)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleExtraModalCancel = () => {
    setExtraModalConfig(null)
  }

  useEffect(() => {
    if (data) {
      const filters =
        data.appMoreSectionTypes?.data.map(item => {
          let regions: { id: string, value: string, label: string }[] | undefined
          if (item?.attributes?.regions?.data)
            regions = item.attributes.regions.data.map(region => { return { id: region.id!, value: region.attributes!.name, label: region.attributes!.name } })

          return {
            id: uuidv4(),
            itemId: +item.id!,
            name: item.attributes!.name!,
            label: item.attributes!.label!,
            isHidden: item.attributes!.isHidden!,
            regions: regions
          }
        }) || []
      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [data])

  return (
    <div className={"modal-sections-management-wrapper"}>
      <div>
        <div className={"title"}>Section Management</div>
        <div className={"additional-description"}>
          Here, you have the ability to create, delete, hide, or relocate bynder as needed.
        </div>
      </div>

      <div className={"add-button"} onClick={() => setExtraModalConfig({ mode: "create" })}>
        + Add new section
      </div>

      <div className={"list-wrapper"}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='characters'>
            {provided => (
              <ul className='list' {...provided.droppableProps} ref={provided.innerRef}>
                {filters.map(({ id, itemId, label, name, isHidden }, index) => {
                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {provided => (
                        <li
                          className={"card"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"name"}>
                            <Checkbox
                              onChange={onCheckboxChange}
                              id={index.toString()}
                              defaultChecked={!isHidden}
                              key={id}
                            />
                            <p>{label}</p>
                          </div>
                          {name !== "events" && name !== "product_matchmaker" && (
                            <>
                              <PencilIcon
                                onClick={() => {
                                  setExtraModalConfig({ mode: "edit", id })
                                }}
                              />
                              <TrashGreyIcon
                                onClick={() => {
                                  setExtraModalConfig({ mode: "delete", id })
                                }}
                              />
                            </>
                          )}
                          <EditSixDots className={"disabled"} />
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div onClick={() => !isItemsEqual && onSave()} className={`save-button ${!isItemsEqual && "active"}`}>
        Save
        {loading ? <Spin /> : <SaveIcon />}
      </div>

      <ExtraModal
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        id={extraModalConfig?.id}
        mode={extraModalConfig?.mode}
        handleExtraModalCancel={handleExtraModalCancel}
      />
    </div>
  )
}

export default SectionsManagement