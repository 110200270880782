import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { truncate } from "../../../../../../../utils"
import "./index.less"
import { useGetMoreSectionTypesQuery } from "../../../../../../../graphql"
import { useAuth } from "../../../../../../../../src/components/auth"

const Filter: FC<LinkFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const { region, role } = useAuth()
  const allRegions = ["Asia - Oceania", "Europe - Middle East - Africa", "North America"]
  const regionsOfAssets = role?.toLowerCase().includes('super') ? allRegions : [region.name || '']
  const { data: moreSectionTypes } = useGetMoreSectionTypesQuery({ fetchPolicy: "network-only", variables: { region: regionsOfAssets } })
  const types: SelectItems | undefined =
    moreSectionTypes?.appMoreSectionTypes?.data
      ?.filter(item => item.attributes!.name !== "product_matchmaker" && item.attributes!.name !== "events")
      ?.map(item => ({
        value: item.attributes!.name!,
        label: item.attributes!.label!,
        key: String(item.id)
      })) || []
  types.unshift({ value: "All", label: "All", key: "0" })

  const resetFilter = (type: string) => {
    switch (type) {
      case "title":
        setFilters({ ...filters, selectedTitle: "All" })
        break
      case "address":
        setFilters({ ...filters, selectedAddress: "All" })
        break
    }
  }

  return (
    <div className={`filters-wrapper links-filter`}>
      <div className={"filters"}>
        <Dropdown
          disabled={!types.length}
          className={`type-filter ${filters.selectedStatus != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedStatus: types.find(item => +item.key == +e.key)!.value! })
              }
              selectedKeys={[`${types.find(item => item.value == filters.selectedStatus)!.key}`]}
            >
              {types?.map(item => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {types.find(item => item.value == filters.selectedStatus)!.label}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Selected Asset:
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div className={"block"} key={`filter-${i}`} style={{ backgroundColor: filter.backgroundColor, color: filter.color }}>
                {truncate(filter.option, 32)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
