import React, { FC, useMemo, useState } from "react"
import { ReactComponent as Logo } from "../../icons/logo.svg"
import defaultCover from "../../images/admin-login-default-cover.webp"
import Form from "./components/form"
import "./index.less"

const Login: FC = () => {
  const forgotPasModeOnly: boolean = useMemo(() => window.location.pathname.includes('forgot-password'), [])
  const [forgotPasMode, setForgotPasMode] = useState<boolean>(forgotPasModeOnly ? true : false)

  const changeMode = (type: boolean) => {
    if (forgotPasModeOnly)
      return setForgotPasMode(true)
    setForgotPasMode(type)
  }
  return (
    <main>
      <div className={"general-wrapper"} style={forgotPasMode ? { transform: "translateX(-100vw)" } : {}}>
        <section className={"login"}>
          <div className={"main-wrapper"}>
            <div className={"left-side"}>
              <img src={defaultCover} alt={"cover_image"} />
            </div>
            <div></div>
            <div className={`right-side`}>
              <div className={"logo-wrapper"}>
                <Logo className={"logo"} />
              </div>
              <Form changeMode={changeMode} forgotPasMode={false} forgotPasModeOnly={forgotPasModeOnly} />
            </div>
          </div>
        </section>
        <section className={"login"}>
          <div className={"main-wrapper"}>
            <div className={"left-side"}>
              <img src={defaultCover} alt={"cover_image"} />
            </div>
            <div></div>
            <div className={`right-side`}>
              <div className={"logo-wrapper"}>
                <Logo className={"logo"} />
              </div>
              <Form changeMode={changeMode} forgotPasMode={true} forgotPasModeOnly={forgotPasModeOnly} />
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default Login
