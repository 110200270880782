import React, { FC, useEffect, useRef, useState } from "react"
import { useGetSalonsLazyQuery } from "../../../../../graphql"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC = () => {
  const { region } = useAuth()
  const [getSalons] = useGetSalonsLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [filters, setFilters] = useState<{ [key: string]: string | boolean }>({
    selectedSM: "All",
    selectedName: "All",
    selectedEmail: "All",
    selectedStatus: "All",
    selectedInsiderStatus: "All",
    selectedRegion: region?.name ? region.name : "All",
    selectedDistributor: "All",
  })

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => {

    const {
      selectedStatus: status,
      selectedEmail: email,
      selectedName: name,
      selectedRegion: region,
      selectedSM: salesManager,
      selectedDistributor: distributor,
      selectedInsiderStatus: insiderStatus
    } = filters

    return {
      ...(name !== "All" ? { name: { contains: name, notNull: true } } : {}),
      ...(region !== "All" ? { region: { name: { contains: region } } } : {}),
      ...(insiderStatus == "Allowed" ? { insiderPermission: { eq: true } } : {}),
      ...(insiderStatus == "Not Allowed" ? {
        or:
          [
            { insiderPermission: { eq: true } },
            { insiderPermission: { eq: null } }
          ],
      } : {}),
      ...(distributor !== "All" ? { distributor: { name: { contains: distributor } } } : {}),
      ...(salesManager !== "All" ? { sales_manager: { name: { contains: salesManager } } } : {}),
      owner: {
        ...(email !== "All" ? { email: { contains: email, notNull: true } } : {}),
        ...(status === "Blocked" ? { blocked: { eq: true } } : {}),
        ...(status === "Active" ? { isAppActivated: { eq: true }, blocked: { eq: false } } : {}),
        ...(status === "Inactive" ?
          {
            or:
              [
                { isAppActivated: { eq: false }, blocked: { eq: false } },
                { isAppActivated: { eq: null }, blocked: { eq: false } }
              ],
          } : {}),
      }
    }

  }

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) setList(undefined)

    let newList

    newList = await getSalons({
      fetchPolicy: "no-cache",
      variables: {
        pagination: {
          pageSize: isForExport ? 10000 : pageSize,
          page: isForExport ? undefined : page,
        },
        sort: "name:asc",
        filter: createQueryFilters() as UsersPermissionsUserFiltersInput,
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.salons?.meta?.pagination?.total || 0
    finalData = newList?.data?.salons?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const getStatus = (item: any) => {
    const owner = item?.attributes?.owner?.data?.attributes
    return owner?.blocked ? "blocked" : owner?.isAppActivated ? "active" : "inactive"
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.attributes?.name || "-",
        item?.attributes?.owner?.data?.attributes?.email || "-",
        item?.attributes?.sales_manager?.data?.attributes?.name || "-",
        item?.attributes?.distributor?.data?.attributes?.name || "-",
        item?.attributes?.region?.data?.attributes?.name || "-",
        getStatus(item)
      ]) || []
    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["NAME", "EMAIL", "SALES MANAGER", "DISTRIBUTOR", "REGION", "STATUS"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedName !== "All")
      list.push({
        type: "name",
        color: "#D7A3AB",
        option: filters.selectedName,
        backgroundColor: "rgba(215, 163, 171, 0.15)",
      })
    if (filters.selectedEmail !== "All")
      list.push({
        type: "email",
        color: "#44C3BB",
        option: filters.selectedEmail,
        backgroundColor: "rgba(68, 195, 187, 0.15)",
      })
    if (filters.selectedSM !== "All")
      list.push({
        color: "#2bb350",
        type: "sales-manager",
        option: filters.selectedSM,
        backgroundColor: "rgba(11, 214, 42, 0.15)",
      })
    if (filters.selectedDistributor !== "All")
      list.push({
        color: "#3269a8",
        type: "distributor",
        option: filters.selectedDistributor,
        backgroundColor: "rgba(50, 105, 168, 0.15)",
      })

    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          getStatus,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
