export const formatDate = (inputDate: string, isTime = false): string => {
  if (!inputDate) {
    return "-"
  }

  const date = new Date(inputDate)
  const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const day: number = date.getDate()
  const month: string = months[date.getMonth()]
  const year: number = date.getFullYear()

  if (isTime) {
    const hours: number = date.getHours()
    const minutes: number = date.getMinutes()
    return `${month} ${day}, ${year} at ${hours}:${minutes.toString().padStart(2, "0")}`
  } else {
    return `${day} ${month}, ${year}`
  }
}

export const truncate = (inputString: string, symbols: number) => {
  if (inputString?.length > symbols) {
    return inputString?.substring(0, symbols) + "..."
  } else {
    return inputString || "Unknown"
  }
}

export const removeSelectDuplicates = (array: SelectItems) => {
  const uniqueSet = new Set<string>()
  const uniqueArray: SelectItems = []
  if (!array) return []

  array.forEach(item => {
    if (item.value && !uniqueSet.has(item.value.trim().toLowerCase())) {
      uniqueSet.add(item.value.trim().toLowerCase())
      uniqueArray.push(item)
    }
  })

  return uniqueArray
}

export const areObjectsEqual = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
  notCompareKey?: string
): boolean => {
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && (notCompareKey == undefined || key !== notCompareKey)) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
  }
  return true
}

export const areAllKeysPopulated = (obj: { [key: string]: any }, notRequiredKeys: string[] | []): boolean => {
  const objDeepCopy = JSON.parse(JSON.stringify(obj))
  notRequiredKeys.forEach(key => delete objDeepCopy[key])
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(objDeepCopy, key)) {
      const value = obj[key]
      if (value === null || value === "") {
        return false
      }
    }
  }
  return true
}

export const capitalizeFirstLetter = (str: string | undefined) => {
  if (typeof str !== "string" || str?.length === 0) {
    return str ?? ""
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const capitalizeEveryWord = (str: string) => {
  if (!str) return "-"
  const words = str.replace("_", " ").split(" ")
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  return capitalizedWords.join(" ")
}

export function deepEqualArrays(arr1: any[], arr2: any[]): boolean {
  if (arr1 === arr2) return true

  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i++) {
    const val1 = arr1[i]
    const val2 = arr2[i]

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!deepEqualArrays(val1, val2)) return false
    } else if (typeof val1 === "object" && typeof val2 === "object") {
      if (!areObjectsEqual(val1, val2)) return false
    } else if (val1 !== val2) {
      return false
    }
  }

  return true
}

export const checkFormNotDone = ({
  isDifferentRootValues,
  isTranslationsChanged = false,
}: {
  isDifferentRootValues: boolean
  isTranslationsChanged?: boolean
}): void => {
  if (isDifferentRootValues || isTranslationsChanged) {
    localStorage.setItem("formNotDoneYet", JSON.stringify(true))
  } else {
    localStorage.setItem("formNotDoneYet", JSON.stringify(false))
  }
}

export const setNewTranslationText = ({ newTranslations, lang }: { newTranslations?: any[] | null; lang: string }) => {
  if (!newTranslations) return
  const translationToSet = newTranslations.filter(v => v.targetLanguage === lang)
  return translationToSet[0].text
}
